import React from 'react'
import Link from '../../common/Link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/pro-light-svg-icons'

const ServiceWebOffer = () => (
  <section className="service-web">
    <div className="container">
      <div className="row py-5">
        <div className="col-sm-12 col-md-4">
          <h2 className="text-sm-center text-md-right">
            <span className="text-muted seo-regular">Externalisation de développement</span>
            <br />
            des applications web
          </h2>
        </div>
        <div className="col-sm-12 col-md-8">
          <p>
          Pourquoi l’extrenalisation des projets vers Zaven est-elle utile? Parce que nous collaborons avec les clients dans le monde entier et nous savons comment les guider à travers tout le processus. Dès la conception de vision, les consultations, le prototypage et la création d’une UX, jusqu’à la maintenance et le support en passant par le développement de l’application, sa mise en œuvre, RWD et l’intégration.
          </p>
          <p>
          Notre équipe near-sourcing garantit l’efficacité et une haute performance à prix offshore. Pour en savoir plus sur le modèle idéal pour vous, &nbsp;
            <Link to="/services/poland-software-outsourcing/">
            vérifiez notre guide d’externalisation
            </Link>
            .
          </p>
        </div>
      </div>

      <div className="row pb-5">
        <div className="col-sm-12 col-md-4">
          <div className="seo-h2 text-sm-center text-md-right">
            <span className="text-muted text-regular">
              <strong className="seo-regular">Promesse de l’entreprise</strong> et 
            </span>
            <br />

            <h3 className="seo-span">
              <strong>le cycle de vie d’un logiciel</strong>
            </h3>
          </div>
        </div>
        <div className="col-sm-12 col-md-8">
          <p>
          Nous sommes convaincus que la satisfaction et la confiance sont au cœur de tout partenariat commercial à long terme. Pour s’assurer du bon déroulement de notre collaboration, nous avons adopté les meilleures pratiques en matière du développement et de la mise en œuvre d’un logiciel.
          </p>
          <ul className="fa-ul">
            <li>
              <span className="fa-li">
                <FontAwesomeIcon icon={faAngleRight} />
              </span>
              Solutions entièrement fonctionnelles et conviviales à l’utilisateur
            </li>
            <li>
              <span className="fa-li">
                <FontAwesomeIcon icon={faAngleRight} />
              </span>
              Prix attrayant et excellent rapport qualité / prix,
            </li>
            <li>
              <span className="fa-li">
                <FontAwesomeIcon icon={faAngleRight} />
              </span>
              Adaptation de l’utilisateur élevée
            </li>
            <li>
              <span className="fa-li">
                <FontAwesomeIcon icon={faAngleRight} />
              </span>
              <h4 className="seo-span">Processus de développement agile</h4>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
)

export default ServiceWebOffer
