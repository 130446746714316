import React from 'react'
import Link from '../../common/Link'
 
const ServiceWebTags = () => (
 <section className="service-web bg-faded py-5">
   <div className="container">
     <div className="row mb-5">
       <div className="col-sm-12 col-md-4">
         <h2 className="text-sm-center text-md-right">
           <span className="text-muted seo-regular">Une équipe</span>
           <br />
           <strong>de développeurs agile</strong>
         </h2>
       </div>
       <div className="col-sm-12 col-md-8">
         <p>
           Nous nous assurons toujours d’avoir affecté à votre projet les personnes les plus appropriées car dans notre opinion une équipe bien adoptée et la clé du succès. Tout groupe de projet comporte des programmeurs, des concepteurs, des spécialistes en UI et des experte en front-end. Pour en savoir plus,
 
           <Link to="/about/team/">{' '}faites connaissance de notre équipe.</Link>.
         </p>
       </div>
     </div>
 
     <div className="row">
       <div className="col-sm-12 col-md-4">
         <h2 className="text-sm-center text-md-right">
           <span className="text-muted seo-regular">Solutions en matière</span>
           <br />
           d’applications web pour entreprises
         </h2>
       </div>
       <div className="col-sm-12 col-md-8">
         <div className="seo-p">
           Notre
           <h2 className="seo-span">service de développement des applications web</h2> vous permettra d’atteindre vos objectifs commerciaux quel que soit votre secteur : e-learning, ERP, BPA, Médias Sociaux, E-commerce, Gaming, Innovations, Health-tech ou style de vie.
 
         </div>
         <ul className="list-unstyled list-inline list-badges">
           <li>
             <span className="badge badge-light">.NET</span>
           </li>
           <li>
             <span className="badge badge-light">.NET MVC</span>
           </li>
           <li>
             <span className="badge badge-light">Ruby on Rails</span>
           </li>
           <li>
             <span className="badge badge-light">HTML5</span>
           </li>
           <li>
             <span className="badge badge-light">ExpressJS</span>
           </li>
           <li>
             <span className="badge badge-light">Zend</span>
           </li>
           <li>
             <span className="badge badge-light">ASO.NET</span>
           </li>
           <li>
             <span className="badge badge-light">Angular JS</span>
           </li>
           <li>
             <span className="badge badge-light">Prototype testing</span>
           </li>
           <li>
             <span className="badge badge-light">QA testing</span>
           </li>
           <li>
             <span className="badge badge-light">Responsive web design</span>
           </li>
           <li>
             <span className="badge badge-light">Support & Maintenance</span>
           </li>
           <li>
             <span className="badge badge-light">Static websites</span>
           </li>
           <li>
             <span className="badge badge-light">
               Mobile-friendly websites
             </span>
           </li>
           <li>
             <span className="badge badge-light">Service integrations</span>
           </li>
         </ul>
         <div className="clearfix" />
       </div>
     </div>
 
     <div className="row">
       <div className="col-sm-12 col-md-4" />
       <div className="col-sm-12 col-md-8" />
     </div>
   </div>
 </section>
)
 
export default ServiceWebTags
 