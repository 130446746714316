import React from 'react'
import { Category, scrollToForm } from '../../contact/ContactForm'

const ServiceWebCta = () => (
  <section className="service-web">
    <div className="container">
      <div className="row py-5">
        <div className="col-sm-12 col-md-4 col-lg-6">
          <div className="lead text-sm-center text-md-right">
            <h3 className="seo-span seo-strong text-muted">
            Applications web sur mesure.
            </h3>
            <br />
            <span className="text-service">
            &nbsp;La qualité supérieure de logiciel à 
              <strong className="span-regular"> prix offshoring</strong>
            </span>
            <br />
            <a
              href="#contact-partial"
              onClick={event => {
                event.preventDefault()
                scrollToForm(Category.WebApps, 0, 500)
              }}
              className="btn btn-outline-info my-2"
              id="custom-web-app-talk-btn"
            >
              Consultez notre offre 
            </a>
          </div>
        </div>
        <div className="col-sm-12 col-md-8 col-lg-6">
          <div className="seo-p">
          Nos programmeurs sont compétents et expérimentés en création des applications web non standard pour nos clients du monde entier. Nous offrons les services de modernisation de back-end et les connexions de nuage. En outre, notre équipe peut actualiser le front-end existant en améliorant la vitesse de fonctionnement du logiciel. Les développeurs de Zaven sont efficaces, rentables et orientés vers les besoins du client.
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default ServiceWebCta
